import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  list: {
    marginBottom: "12px",
  },
}));

const ConfirmationDialog = (props) => {
  const classes = useStyles();
  const { onCancel, onConfirm, open, diffList, ...other } = props;
  const handleCancel = () => {
    onCancel();
  };

  const handleOk = () => {
    onConfirm();
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="s"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">
        Mapping Change Confirmation
      </DialogTitle>
      <DialogContent dividers>
        <div>Would like to deploy the following market changes:</div>
        <ul>
          {diffList && diffList.length? (
            diffList.map((entry, index) => (
              <li className={classes.list} key={index}>
                {entry}
              </li>
            ))
          ) : (
            <li>No changes detected</li>
          )}
        </ul>
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
