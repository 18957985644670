export const MAPPING_STATUS_REQUEST = "YSMUI/MAPPING/STATUS/FETCH_REQUEST";
export const MAPPING_STATUS_SUCCESS = "YSMUI/MAPPING/STATUS/FETCH_SUCCESS";
export const MAPPING_STATUS_ERROR = "YSMUI/MAPPING/STATUS/FETCH_ERROR";

export const MAPPING_ACTIVE_REQUEST = "YSMUI/MAPPING/ACTIVE/FETCH_REQUEST";
export const MAPPING_ACTIVE_SUCCESS = "YSMUI/MAPPING/ACTIVE/FETCH_SUCCESS";
export const MAPPING_ACTIVE_ERROR = "YSMUI/MAPPING/ACTIVE/FETCH_ERROR";

export const MAPPING_DEPLOY_REQUEST = "YSMUI/MAPPING/DEPLOY_REQUEST";
export const MAPPING_DEPLOY_SUCCESS = "YSMUI/MAPPING/DEPLOY_SUCCESS";
export const MAPPING_DEPLOY_ERROR = "YSMUI/MAPPING/DEPLOY_ERROR";

export default function (state = [], action) {
  switch (action.type) {
    case MAPPING_STATUS_SUCCESS:
    case MAPPING_ACTIVE_SUCCESS:
    case MAPPING_DEPLOY_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export const fetchMappingStatusAction = () => ({
  type: MAPPING_STATUS_REQUEST,
});

export const fetchActiveMappingAction = () => ({
  type: MAPPING_ACTIVE_REQUEST,
});

export const deployMappingAction = () => ({
  type: MAPPING_DEPLOY_REQUEST,
});

export const getActiveMapping = (state) => {
  return state.mapping.active ? state.mapping.active.data : null;
};

export const getCurrentMapping = (state) => {
  return state.countries.reduce((a, country) => {
    if (country["market_id"]) {
      a[country["id"]] = country["market_id"];
    }
    return a;
  }, {});
};
