import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import PublicIcon from "@material-ui/icons/Public";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import ListIcon from "@material-ui/icons/List";
import { blue } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 0,
  },
  avatar: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
  },
}));

const DataGauge = ({ countries, markets, mappingStatus }) => {
  const classes = useStyles();

  return (
    <List className={classes.root}>
      <ListItem>
        <ListItemAvatar>
          <Avatar className={classes.avatar}>
            <PublicIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Countries" secondary={countries.length} />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar className={classes.avatar}>
            <AccountBalanceIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Markets" secondary={markets.length} />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar className={classes.avatar}>
            <MoneyOffIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Non-Monetized Countries"
          secondary={
            countries.filter((country) => country.market_id === null).length
          }
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar className={classes.avatar}>
            <ListIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Mapping Status" secondary={mappingStatus || 'unavailable'} />
      </ListItem>
    </List>
  );
};
export default DataGauge;
