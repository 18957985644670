import React from "react";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import DataTable from "components/ui/DataTable";
import DataGauge from "./DataGauge";
import ConfirmationDialog from "./ConfirmationDialog";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import yahooMarketApi from "api/YahooMarketApi";
import diff from "deep-diff";
import { setCountryMarketAction } from "redux/reducers/country";
import {
  fetchMappingStatusAction,
  deployMappingAction,
  getCurrentMapping,
  getActiveMapping,
} from "redux/reducers/mapping";
import { signoutAction } from "redux/reducers/auth";
import { downloadLocalFile } from "utils/helpers";

const useStyles = makeStyles((theme) => ({
  control: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    padding: "24px",
  },
  paper: {
    width: "80%",
    maxHeight: 435,
  },
}));

const Dashboard = ({
  markets,
  countries,
  mapping,
  setCountryMarket,
  fetchMappingStatus,
  deployMapping,
  activeMapping,
  currentMapping,
  signout,
  authStatus,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [diffList, setDiffList] = React.useState([]);
  const columns = [
    {
      title: "Country",
      field: "country",
      editable: "never",

      render: (rowData) => (
        <div>
          <img
            style={{ verticalAlign: "middle" }}
            src={`images/countries/${rowData.code}.png`}
            height="16px"
            alt={rowData.country}
          />
          <span style={{ marginLeft: "8px" }}>{rowData.country}</span>
        </div>
      ),
    },
    {
      title: "Market",
      field: "market",
      lookup: {
        0: "Not Assigned",
        ...markets.reduce((a, market) => {
          a[market.id] = market.name;
          return a;
        }, {}),
      },
    },
  ];
  const data = countries.map((country) => {
    return {
      id: country.id,
      country: country.name,
      code: country.code,
      market: country.market_id || 0,
      editable: country.base_market === 0,
    };
  });

  const getCountryById = (id) => {
    return countries.find((country) => country.id === id);
  };

  const getMarketById = (id) => {
    return markets.find((market) => market.id === id);
  };

  const handelCancelDialog = () => {
    setOpen(false);
  };

  const handelConfirmDialog = () => {
    deployMapping();
    setOpen(false);
  };

  const handleDeploy = () => {
    let diffLog = [];
    if (!activeMapping) {
      for (let countryId in currentMapping) {
        diffLog.push(
          "Added: " +
            getCountryById(parseInt(countryId))["name"] +
            " => " +
            getMarketById(currentMapping[countryId])["name"]
        );
      }
    } else {
      const diffData = diff(activeMapping, currentMapping);
      diffLog = diffData
        ? diffData.map((diffEntry) => {
            const country = getCountryById(parseInt(diffEntry.path[0]))["name"];
            switch (diffEntry.kind) {
              case "N":
                return (
                  "Added: " +
                  country +
                  " => " +
                  getMarketById(diffEntry.rhs)["name"]
                );
              case "D":
                return (
                  "Deleted: " +
                  country +
                  " => " +
                  getMarketById(diffEntry.lhs)["name"]
                );
              case "E":
                return (
                  "Modified: " +
                  country +
                  " From " +
                  getMarketById(diffEntry.lhs)["name"] +
                  " to " +
                  getMarketById(diffEntry.rhs)["name"]
                );
              default:
                return "No change";
            }
          })
        : [];
    }

    setDiffList(diffLog);
    setOpen(true);
  };

  const handleExport = () => {
    let data = "Country,Country Code,Market, Market Code\n";
    for (let countryId in activeMapping) {
      let country = getCountryById(parseInt(countryId));
      let market = getMarketById(activeMapping[countryId]);
      data +=
        '"' +
        country["name"] +
        '","' +
        country["code"] +
        '","' +
        market["name"] +
        '","' +
        market["code"] +
        '"\n';
    }

    downloadLocalFile("active_mapping.csv", data);
  };

  return (
    <React.Fragment>
      {authStatus !== "authenticated" ? <Redirect to="/signin" /> : ""}
      <DataGauge
        countries={countries}
        markets={markets}
        mappingStatus={mapping.status}
      />
      <div className={classes.control}>
        <Button
          variant="contained"
          color="primary"
          disabled={mapping.status !== "modified"}
          onClick={handleDeploy}
        >
          Deploy Mapping
        </Button>
        <Button variant="contained" color="primary" onClick={handleExport}>
          Export Active Mapping
        </Button>
        <Button variant="contained" color="primary" onClick={signout}>
          Signout
        </Button>
      </div>
      <DataTable
        title="Yahoo Market Mapping"
        columns={columns}
        data={data}
        isLoading={markets.length === 0 || countries.length === 0}
        editable={{
          isEditable: (rowData) => rowData.editable,
          onRowUpdate: (newData, oldData) => {
            if (parseInt(oldData.market) !== parseInt(newData.market)) {
              return yahooMarketApi
                .setCountryMarket(newData.id, newData.market)
                .then((response) => {
                  setCountryMarket(
                    response.data.country_id,
                    response.data.market_id
                  );
                  fetchMappingStatus();
                });
            }

            return Promise.resolve();
          },
        }}
        options={{
          actionsColumnIndex: -1,
          draggable: false,
          pageSize: 15,
          pageSizeOptions: [15, 25, 50],
          rowStyle: (rowData) => ({
            backgroundColor:
              parseInt(rowData.market) === 0 ? "#fbe9e7" : "#ccff90",
          }),
        }}
      />
      <ConfirmationDialog
        classes={{
          paper: classes.paper,
        }}
        keepMounted
        diffList={diffList}
        open={open}
        onCancel={handelCancelDialog}
        onConfirm={handelConfirmDialog}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  countries: state.countries,
  markets: state.markets,
  mapping: state.mapping,
  activeMapping: getActiveMapping(state),
  currentMapping: getCurrentMapping(state),
  authStatus: state.auth.status,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setCountryMarket: (country_id, market_id) => {
      dispatch(setCountryMarketAction(country_id, market_id));
    },
    fetchMappingStatus: () => {
      dispatch(fetchMappingStatusAction());
    },
    deployMapping: () => {
      dispatch(deployMappingAction());
    },
    signout: () => {
      dispatch(signoutAction());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
