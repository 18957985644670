import { createLogic } from "redux-logic";
import {
  MAPPING_STATUS_REQUEST,
  MAPPING_STATUS_SUCCESS,
  MAPPING_STATUS_ERROR,
  MAPPING_ACTIVE_REQUEST,
  MAPPING_ACTIVE_SUCCESS,
  MAPPING_ACTIVE_ERROR,
  MAPPING_DEPLOY_REQUEST,
  MAPPING_DEPLOY_SUCCESS,
  MAPPING_DEPLOY_ERROR,
} from "redux/reducers/mapping";

const fetchMappingStatus = createLogic({
  type: MAPPING_STATUS_REQUEST,
  latest: true,
  process({ yahooMarketApi }, dispatch, done) {
    yahooMarketApi
      .getMappingStatus()
      .then((response) => {
        if (response.status === "success") {
          dispatch({ type: MAPPING_STATUS_SUCCESS, payload: response.data });
        } else {
          dispatch({ type: MAPPING_STATUS_ERROR, payload: response.message });
        }
      })
      .catch((err) => {
        dispatch({ type: MAPPING_STATUS_ERROR, payload: "Error calling API" });
      })
      .then(() => done());
  },
});

const fetchActiveMapping = createLogic({
  type: MAPPING_ACTIVE_REQUEST,
  latest: true,
  process({ yahooMarketApi }, dispatch, done) {
    yahooMarketApi
      .getActiveMapping()
      .then((response) => {
        if (response.status === "success") {
          dispatch({
            type: MAPPING_ACTIVE_SUCCESS,
            payload: { active: response.data.mapping },
          });
        } else {
          dispatch({ type: MAPPING_ACTIVE_ERROR, payload: response.message });
        }
      })
      .catch((err) => {
        dispatch({ type: MAPPING_ACTIVE_ERROR, payload: "Error calling API" });
      })
      .then(() => done());
  },
});

const deployMapping = createLogic({
  type: MAPPING_DEPLOY_REQUEST,
  latest: true,
  process({ yahooMarketApi }, dispatch, done) {
    yahooMarketApi
      .deployMapping()
      .then((response) => {
        if (response.status === "success") {
          dispatch({
            type: MAPPING_DEPLOY_SUCCESS,
            payload: { deploy: "success" },
          });
          dispatch({
            type: MAPPING_ACTIVE_SUCCESS,
            payload: { active: response.data.mapping },
          });
          dispatch({
            type: MAPPING_STATUS_SUCCESS,
            payload: { status: "deployed" },
          });
        } else {
          dispatch({ type: MAPPING_DEPLOY_ERROR, payload: response.message });
        }
      })
      .catch((err) => {
        dispatch({ type: MAPPING_DEPLOY_ERROR, payload: "Error calling API" });
      })
      .then(() => done());
  },
});

export default [fetchMappingStatus, fetchActiveMapping, deployMapping];
