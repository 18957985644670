const SESSION_KEY = "session";

const saveSessionToken = (token) => {
  localStorage.setItem(SESSION_KEY, token);
};

const getSessionToken = () => {
  return localStorage.getItem(SESSION_KEY);
};

const deleteSessionToken = () => {
  return localStorage.removeItem(SESSION_KEY);
};

const callApi = (method, path, data = null) => {
  const url = "https://yscm.mystart.com/api" + path;

  return fetch(url, {
    method,
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getSessionToken(),
    },
    redirect: "follow",
    body: data ? JSON.stringify(data) : null,
  })
    .then((response) => response.json())
    .catch((e) => {
      console.log("Error calling API", e);
    });
};

const YahooMarketApi = {
  getCountries: () => {
    return callApi("GET", "/countries");
  },

  getMarkets: () => {
    return callApi("GET", "/markets");
  },

  getMappingStatus: () => {
    return callApi("GET", "/mapping/status");
  },

  getActiveMapping: () => {
    return callApi("GET", "/mapping/active");
  },

  deployMapping: () => {
    return callApi("POST", "/mapping/deploy");
  },

  signin: (username, password) => {
    return callApi("POST", "/auth/signin", { username, password }).then(
      (response) => {
        saveSessionToken(response.data.token);
        return response;
      }
    );
  },

  signout: () => {
    return callApi("GET", "/auth/signout").then((reponse) => {
      deleteSessionToken();
      return reponse;
    });
  },

  validateSession: () => {
    return callApi("GET", "/auth/validate").catch((e) => {
      deleteSessionToken();
    });
  },

  isSignedIn: () => {
    return getSessionToken() ? true : false;
  },

  setCountryMarket: (countryId, marketId) => {
    return callApi("POST", `/countries/${countryId}/market`, {
      market: marketId,
    });
  },
};

export default YahooMarketApi;
