export const COUNTRY_FETCH_REQUEST = "YSMUI/COUNTRY/FETCH_REQUEST";
export const COUNTRY_FETCH_SUCCESS = "YSMUI/COUNTRY/FETCH_SUCCESS";
export const COUNTRY_FETCH_ERROR = "YSMUI/COUNTRY/FETCH_ERROR";

export const COUNTRY_UPDATE_REQUEST = "YSMUI/COUNTRY/UPDATE_REQUEST";
export const COUNTRY_UPDATE_SUCCESS = "YSMUI/COUNTRY/UPDATE_SUCCESS";
export const COUNTRY_UPDATE_ERROR = "YSMUI/COUNTRY/UPDATE_ERROR";

export default function (state = [], action) {
  switch (action.type) {
    case COUNTRY_FETCH_SUCCESS:
      return [...action.payload];
    case COUNTRY_UPDATE_SUCCESS:
      return state.map((country) => {
        if (country.id === action.payload.country_id) {
          return { ...country, market_id: action.payload.market_id };
        }
        return country;
      });
    default:
      return state;
  }
}

export const fetchCountriesAction = () => ({
  type: COUNTRY_FETCH_REQUEST,
});

export const setCountryMarketAction = (country_id, market_id) => ({
  type: COUNTRY_UPDATE_SUCCESS,
  payload: { country_id, market_id },
});
