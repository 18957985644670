export const MARKET_FETCH_REQUEST = "YSMUI/MARKET/FETCH_REQUEST";
export const MARKET_FETCH_SUCCESS = "YSMUI/MARKET/FETCH_SUCCESS";
export const MARKET_FETCH_ERROR = "YSMUI/MARKET/FETCH_ERROR";

export default function (state = [], action) {
  switch (action.type) {
    case MARKET_FETCH_SUCCESS:
      return [...action.payload];
    default:
      return state;
  }
}

export const fetchMarketsAction = () => ({
  type: MARKET_FETCH_REQUEST,
});