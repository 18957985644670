import { combineReducers } from "redux";
import countryReducer from "./country";
import marketReducer from "./market";
import mappingReducer from "./mapping";
import authReducer from "./auth";

const combinedReducer = combineReducers({
  countries: countryReducer,
  markets: marketReducer,
  mapping: mappingReducer,
  auth: authReducer,
});

const rootReducer = (state, action) => {
  return combinedReducer(state, action);
};

export default rootReducer;
