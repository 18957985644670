export const AUTH_SIGNIN_REQUEST = "YSMUI/AUTH/SIGIN_REQUEST";
export const AUTH_SIGNIN_SUCESS = "YSMUI/AUTH/SIGIN_SUCCESS";
export const AUTH_SIGNIN_ERROR = "YSMUI/AUTH/SIGIN_ERROR";
export const AUTH_SIGNOUT_REQUEST = "YSMUI/AUTH/SIGOUT_REQUEST";
export const AUTH_SIGNOUT_SUCESS = "YSMUI/AUTH/SIGOUT_SUCCESS";
export const AUTH_SIGNOUT_ERROR = "YSMUI/AUTH/SIGOUT_ERROR";
export const AUTH_VALIDATE_REQUEST = "YSMUI/AUTH/VALIDATE_REQUEST";
export const AUTH_VALIDATE_SUCESS = "YSMUI/AUTH/VALIDATE_SUCCESS";
export const AUTH_VALIDATE_ERROR = "YSMUI/AUTH/VALIDATE_ERROR";

export default function (state = [], action) {
  switch (action.type) {
    case AUTH_SIGNIN_SUCESS:
    case AUTH_VALIDATE_SUCESS:
      return { status: "authenticated" };
    case AUTH_VALIDATE_ERROR:
    case AUTH_SIGNOUT_SUCESS:
      return { status: "unauthenticated" };
    case AUTH_SIGNIN_ERROR:
    case AUTH_SIGNOUT_ERROR:
      return { status: "error" };
    default:
      return state;
  }
}

export const signinAction = (username, password) => ({
  type: AUTH_SIGNIN_REQUEST,
  payload: { username, password },
});

export const signoutAction = () => ({
  type: AUTH_SIGNOUT_REQUEST,
});

export const validateSessionAction = () => ({
  type: AUTH_VALIDATE_REQUEST,
});
