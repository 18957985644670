import { createLogic } from "redux-logic";
import {
  COUNTRY_FETCH_REQUEST,
  COUNTRY_FETCH_SUCCESS,
  COUNTRY_FETCH_ERROR,
} from "redux/reducers/country";

const fetchCountries = createLogic({
  type: COUNTRY_FETCH_REQUEST,
  latest: true,
  process({ yahooMarketApi }, dispatch, done) {
    yahooMarketApi
      .getCountries()
      .then((response) => {
        if (response.status === "success") {
          dispatch({ type: COUNTRY_FETCH_SUCCESS, payload: response.data });
        } else {
          dispatch({ type: COUNTRY_FETCH_ERROR, payload: response.message });
        }
      })
      .catch((err) => {
        dispatch({ type: COUNTRY_FETCH_ERROR, payload: "Error calling API" });
      })
      .then(() => done());
  },
});

export default [fetchCountries];
