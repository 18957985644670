import { createLogic } from "redux-logic";
import {
  AUTH_SIGNIN_REQUEST,
  AUTH_SIGNIN_SUCESS,
  AUTH_SIGNIN_ERROR,
  AUTH_SIGNOUT_REQUEST,
  AUTH_SIGNOUT_SUCESS,
  AUTH_SIGNOUT_ERROR,
  AUTH_VALIDATE_REQUEST,
  AUTH_VALIDATE_SUCESS,
  AUTH_VALIDATE_ERROR,
} from "redux/reducers/auth";

import { fetchCountriesAction } from "redux/reducers/country";
import { fetchMarketsAction } from "redux/reducers/market";
import { fetchMappingStatusAction } from "redux/reducers/mapping";
import { fetchActiveMappingAction } from "redux/reducers/mapping";

const validateSession = createLogic({
  type: AUTH_VALIDATE_REQUEST,
  latest: true,
  process({ yahooMarketApi }, dispatch, done) {
    yahooMarketApi
      .validateSession()
      .then((response) => {
        if (response.status === "success") {
          dispatch({ type: AUTH_VALIDATE_SUCESS, payload: response.data });
          dispatch(fetchCountriesAction());
          dispatch(fetchMarketsAction());
          dispatch(fetchMappingStatusAction());
          dispatch(fetchActiveMappingAction());
        } else {
          dispatch({ type: AUTH_VALIDATE_ERROR, payload: response.message });
        }
      })
      .catch((err) => {
        dispatch({ type: AUTH_VALIDATE_ERROR, payload: "Error calling API" });
      })
      .then(() => done());
  },
});

const signin = createLogic({
  type: AUTH_SIGNIN_REQUEST,
  latest: true,
  process({ yahooMarketApi, action }, dispatch, done) {
    const { username, password } = action.payload;
    yahooMarketApi
      .signin(username, password)
      .then((response) => {
        if (response.status === "success") {
          dispatch({ type: AUTH_SIGNIN_SUCESS, payload: response.data });
          dispatch({ type: AUTH_VALIDATE_REQUEST });
        } else {
          dispatch({ type: AUTH_SIGNIN_ERROR, payload: response.message });
        }
      })
      .catch((err) => {
        dispatch({ type: AUTH_SIGNIN_ERROR, payload: "Error calling API" });
      })
      .then(() => done());
  },
});

const signout = createLogic({
  type: AUTH_SIGNOUT_REQUEST,
  latest: true,
  process({ yahooMarketApi }, dispatch, done) {
    yahooMarketApi
      .signout()
      .then((response) => {
        if (response.status === "success") {
          dispatch({ type: AUTH_SIGNOUT_SUCESS, payload: response.data });
        } else {
          dispatch({ type: AUTH_SIGNOUT_ERROR, payload: response.message });
        }
      })
      .catch((err) => {
        dispatch({ type: AUTH_SIGNOUT_ERROR, payload: "Error calling API" });
      })
      .then(() => done());
  },
});

export default [validateSession, signin, signout];
