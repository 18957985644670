import React, { useLayoutEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import PrivateRoute from "components/route/privateroute";
import Dashboard from "views/dashboard";
import SignInView from "views/signin";
import store from "redux/store";

import {validateSessionAction} from 'redux/reducers/auth';

import "./App.css";

const App = () => {
  useLayoutEffect(() => {
    store.dispatch(validateSessionAction());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <PrivateRoute exact path="/" component={Dashboard} />
        <Route path="/signin" component={SignInView} />
      </Router>
    </Provider>
  );
};

export default App;
