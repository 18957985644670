import { createStore, applyMiddleware, compose } from "redux";
import yahooMarketApi from "api/YahooMarketApi";

import { createLogicMiddleware } from "redux-logic";
import arrLogic from "./logic";

import rootReducer from "./reducers";

const initialState = {
};
const middlewares = [];

const composeEnhancers =
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

middlewares.push(createLogicMiddleware(arrLogic, { yahooMarketApi }));

const configureStore = () => {
  return {
    ...createStore(
      rootReducer,
      initialState,
      composeEnhancers(applyMiddleware(...middlewares))
    ),
  };
};

const store = configureStore();

export default store;
