import { createLogic } from "redux-logic";
import {
  MARKET_FETCH_REQUEST,
  MARKET_FETCH_SUCCESS,
  MARKET_FETCH_ERROR,
} from "redux/reducers/market";

const fetchMarkets = createLogic({
  type: MARKET_FETCH_REQUEST,
  latest: true,
  process({ yahooMarketApi }, dispatch, done) {
    yahooMarketApi
      .getMarkets()
      .then((response) => {
        if (response.status === "success") {
          dispatch({ type: MARKET_FETCH_SUCCESS, payload: response.data });
        } else {
          dispatch({ type: MARKET_FETCH_ERROR, payload: response.message });
        }
      })
      .catch((err) => {
        dispatch({ type: MARKET_FETCH_ERROR, payload: "Error calling API" });
      })
      .then(() => done());
  },
});

export default [fetchMarkets];
